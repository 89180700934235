import React, { useState } from "react"

import HCaptcha from "@hcaptcha/react-hcaptcha"
import Paragraph from "decentraland-gatsby/dist/components/Text/Paragraph"
import SubTitle from "decentraland-gatsby/dist/components/Text/SubTitle"
import useAsyncTask from "decentraland-gatsby/dist/hooks/useAsyncTask"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Hero } from "decentraland-ui/dist/components/Hero/Hero"

export default React.memo(function JoinDiscord() {
  const l = useFormatMessage()
  const [invitation, setInvitation] = useState<null | string>(null)
  const [inviting, invite] = useAsyncTask(async (token: string) => {
    const res = await fetch("https://discord-lambdas.decentraland.io/captcha", {
      method: "POST",
      body: JSON.stringify({ "captcha-response": token }),
    })

    const body = await res.json()
    setInvitation(`https://discord.com/invite/${body.code}`)
  }, [])

  return (
    <>
      <Hero.Header>
        {/* <Title>Join</Title> */}
        <SubTitle>{l("page.join_discord.title")}</SubTitle>
      </Hero.Header>
      <Hero.Description>
        <Paragraph>{l("page.join_discord.description")}</Paragraph>
      </Hero.Description>
      <Hero.Actions>
        <form style={{ display: invitation || inviting ? "none" : "" }}>
          <HCaptcha
            sitekey="00c95ced-f512-4498-a5f2-7e0b0950d456"
            onVerify={invite}
          />
        </form>
        <Button
          primary={!inviting}
          as="a"
          href={invitation || ""}
          disabled={inviting}
          loading={inviting}
          style={{ display: !invitation && !inviting ? "none" : "" }}
        >
          {l("page.join_discord.cta")}
        </Button>
      </Hero.Actions>
    </>
  )
})
